<template>
  <main id="profile-view">
    <p class="dashboard-header__breadcrumb">{{ $t('user-profile') }}</p>
    <div class="card">
      <div class="card__content">
        <template v-if="authData.role === 'admin'">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('connect-social-accounts') }}</h4>
          </div>
        </div>
        <p>{{ $t('social-accounts-text.p1') }}</p>
        <p>{{ $t('social-accounts-text.p2', { predactive: $t('predactive2') }) }}</p>
        <p>{{ $t('social-accounts-text.p3') }}</p>
        <ul>
          <li>
            <template v-if="isConnectedWithFacebook">
              <input class="input__checkbox input__checkbox--checked input__checkbox--disabled" id="facebookConnected" type="checkbox" v-model="oauthFacebookToken" disabled>
              <label class="input__label input__label--checkbox" for="facebookConnected">{{ $t('fb-bm') }} {{ $t('connected') }} (<strong>{{ authData.fbName }}</strong>)</label>
              <button class="btn btn--new btn--new-secondary" @click="disconnectFacebook">{{ $t('disconnect') }}</button>
            </template>
            <template v-else>
              <input class="input__checkbox input__checkbox--disabled" id="facebookConnected" type="checkbox" v-model="oauthFacebookToken" disabled>
              <label class="input__label input__label--checkbox" for="facebookConnected">{{ $t('fb-bm') }}</label>
              <button class="btn btn--new" @click="connectFacebook">{{ $t('connect') }}</button>
            </template>
          </li>
          <li>
            <template v-if="isConnectedWithTwitter">
              <input class="input__checkbox input__checkbox--checked input__checkbox--disabled" id="twitterConnected" type="checkbox" v-model="oauthTwitterToken" disabled>
              <label class="input__label input__label--checkbox" for="twitterConnected">{{ $t('t-am') }} {{ $t('connected') }} (<strong>{{ authData.twName }}</strong>)</label>
              <button class="btn btn--new btn--new-secondary" @click="disconnectTwitter">{{ $t('disconnect') }}</button>
            </template>
            <template v-else>
              <input class="input__checkbox input__checkbox--disabled" id="twitterConnected" type="checkbox" v-model="oauthTwitterToken" disabled>
              <label class="input__label input__label--checkbox" for="twitterConnected">{{ $t('t-am') }}</label>
              <button class="btn btn--new" @click="connectTwitter">{{ $t('connect') }}</button>
            </template>
          </li>
          <li>
            <input class="input__checkbox input__checkbox--disabled" id="linkedinConnected" type="checkbox" v-model="oauthLinkedinToken">
            <label class="input__label input__label--checkbox" for="linkedinConnected">{{ $t('l-am') }}</label>
            <button class="btn btn--new">{{ $t('connect') }}</button>
          </li>
          <li>
            <input class="input__checkbox input__checkbox--disabled" id="googleConnected" type="checkbox" v-model="oauthGoogleToken">
            <label class="input__label input__label--checkbox" for="googleConnected">{{ $t('g-am') }}</label>
            <button class="btn btn--new">{{ $t('connect') }}</button>
          </li>
        </ul>
        </template>
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('choose-default-language') }}</h4>
          </div>
        </div>
        <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)" :class="`btn-lang ${entry.language === locale ? 'btn-lang--active' : ''}`">
          <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
        </button>
      </div>
    </div>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  GET_OAUTH_FACEBOOK_LINK,
  SAVE_OAUTH_FACEBOOK_TOKEN,
  FACEBOOK_LOGOUT,
  GET_OAUTH_TWITTER_LINK,
  SAVE_OAUTH_TWITTER_TOKEN,
  TWITTER_LOGOUT
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'profile',
  data: function () {
    return {
      locale: i18n.locale,
      oauthLinkedinToken: false,
      oauthGoogleToken: false,
      isConnectedWithFacebook: false,
      isConnectedWithTwitter: false
    }
  },
  // TODO: The token will come inside authData.tokens!!!
  computed: {
    ...mapGetters([
      'oauthFacebookLink',
      'oauthFacebookToken',
      'oauthTwitterLink',
      'oauthTwitterToken',
      'authData'
    ]),
    languages: function () {
      return [
        { flag: 'us', language: 'en', title: this.$t('english') },
        { flag: 'es', language: 'es', title: this.$t('spanish') }
      ]
    }
  },
  watch: {
    'authData.tokens.facebook': function (newValue, oldValue) {
      this.isConnectedWithFacebook = newValue
    },
    'authData.tokens.twitter': function (newValue, oldValue) {
      this.isConnectedWithTwitter = newValue
    }
  },
  mounted () {
    if (this.authData.role === 'admin') {
      this.isConnectedWithFacebook = this.authData?.tokens?.facebook
      this.isConnectedWithTwitter = this.authData?.tokens?.twitter
      // TODO: whats the twitter code???
      if (this.$route.query.code && this.$route.query.code !== '') {
        this.saveOauthFacebookToken(this.$route.query)
      }
      this.getOauthFacebookLink()
      this.getOauthTwitterLink()
    }
  },
  methods: {
    ...mapActions({
      getOauthFacebookLink: GET_OAUTH_FACEBOOK_LINK,
      saveOauthFacebookToken: SAVE_OAUTH_FACEBOOK_TOKEN,
      disconnectFacebook: FACEBOOK_LOGOUT,
      getOauthTwitterLink: GET_OAUTH_TWITTER_LINK,
      saveOauthTwitterToken: SAVE_OAUTH_TWITTER_TOKEN,
      disconnectTwitter: TWITTER_LOGOUT
    }),
    connectFacebook () {
      window.location.href = this.oauthFacebookLink
    },
    connectTwitter () {
      window.location.href = this.oauthTwitterLink
    },
    changeLocale (locale) {
      i18n.locale = this.locale = locale
      localStorage.setItem('defaultLanguage', locale)
    }
  }
}
</script>
<style scoped>
#profile-view {
  display: grid;
  margin: 0 auto;
  max-width: calc(100% - 20px);
  width: 100%;
  padding: 20px;
  grid-template-rows: 50px 1fr;
}
.card__content {
  padding: 20px 20px 40px;
}
p+p {
  margin-top: 10px;
}
ul {
  margin: 20px auto 20px;
  border-bottom: 1px dotted #cccccc;
}
li {
  padding: 5px 10px;
  line-height: 50px;
  border-top: 1px dotted #cccccc;
}
.btn {
  margin-left: 10px;
}
</style>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "choose-default-language": "Choose your language",
    "user-profile": "User Profile",
    "connect-social-accounts": "Connect your social media",
    "social-accounts-text": {
      "p1": "Choose the platform you want to connect with, You will be redirected to its website.",
      "p2": "After identifying yourself you will be redirected back to {predactive}.",
      "p3": "Link your profile to:"
    },
    "connect": "Connect",
    "disconnect": "Disconnect",
    "connected": "connected",
    "fb-bm": "Facebook Bussiness Manager",
    "l-am": "Linkedin Ads Manager",
    "g-am": "Google Ad Manager",
    "t-am": "Twitter Ads Manager"
  },
  "es": {
    "choose-default-language": "Selecciona tu idioma",
    "user-profile": "Perfil de usuario",
    "connect-social-accounts": "Conecta tus redes sociales",
    "social-accounts-text": {
      "p1": "Elige la plataforma con la que quieras conectarte, serás redirigido a su web.",
      "p2": "Después de identificarte serás redirigido de vuelta a {predactive}.",
      "p3": "Enlaza tu perfil a:"
    },
    "connect": "Conectar",
    "disconnect": "Desconectar",
    "connected": "conectado",
    "fb-bm": "Facebook Bussiness Manager",
    "l-am": "Linkedin Ads Manager",
    "g-am": "Google Ad Manager",
    "t-am": "Twitter Ads Manager"
  }
}
</i18n>
